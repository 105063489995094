export enum ComparisonOperator {
  Equal = 0,
  NotEqual = 1,
  GreaterThan = 2,
  GreaterThanOrEqual = 3,
  LessThan = 4,
  LessThanOrEqual = 5,
  Contains = 6,
  StartsWith = 7,
  EndsWith = 8,
}

export enum ConditionOperator {
  And = 1,
  Or = 2,
}

export interface Condition {
  label: string;
  inputPropertyKey: string;
  key: string;
  conditionOperator: ConditionOperator;
  operator: ComparisonOperator;
  value: string;
}

export interface ExtractedKeys {
  [key: string]: string;
}
