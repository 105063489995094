import { InjectionToken } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { AppConfiguration } from '@root/shared/models/app-configuration.model';
import { CustomPropertiesItem } from '@root/shared/models/custom-properties-item.model';
import { Theme } from '@root/shared/models/theme.model';
import { StepMap } from '@root/shared/models/start.model';
import { OnLanding } from '@root/shared/models/on-landing.model';
import { LayoutModel } from '@root/shared/models/layout.model';
import { SubmitStep } from '@root/shared/models/submit.model';

export interface AppStoreModel {
  appConfiguration: AppConfiguration;
  customProperties: CustomPropertiesItem[];
  processedSteps: StepMap[];
  stepMap: StepMap[];
  currentStepPosition: number;
  stepValidation: boolean;
  onLanding: OnLanding;
  showSkeleton: boolean;
  showFooter: boolean;
  theme: Theme;
  isSpinning: boolean;
  spinningCardId: string;
  layoutModel: LayoutModel;
  submitModel: SubmitStep[];
  submitted: boolean;
  submitMessage: string;
}

const store = createStore(
  {
    name: 'app-configuration',
  },
  withProps<AppStoreModel>({
    appConfiguration: null,
    stepValidation: false,
    processedSteps: [],
    onLanding: null,
    showSkeleton: true,
    stepMap: [],
    currentStepPosition: 0,
    showFooter: null,
    theme: null,
    customProperties: null,
    isSpinning: false,
    spinningCardId: null,
    layoutModel: {} as LayoutModel,
    submitModel: [],
    submitted: false,
    submitMessage: null,
  })
);
persistState(store, {
  storage: sessionStorageStrategy,
});

export const layoutModel$ = store.pipe(select(({ layoutModel }) => layoutModel));
export const onLanding$ = store.pipe(select(({ onLanding }) => onLanding));
export const spinningCardId$ = store.pipe(select(({ spinningCardId }) => spinningCardId));
export const isSpinning$ = store.pipe(select(({ isSpinning }) => isSpinning));
export const appConfiguration$ = store.pipe(select(({ appConfiguration }) => appConfiguration));
export const processedSteps$ = store.pipe(select(({ processedSteps }) => processedSteps));
export const customProperties$ = store.pipe(select(({ customProperties }) => customProperties));
export const stepValidation$ = store.pipe(select(({ stepValidation }) => stepValidation));
export const showSkeleton$ = store.pipe(select(({ showSkeleton }) => showSkeleton));
export const showFooter$ = store.pipe(select(({ showFooter }) => showFooter));
export const theme$ = store.pipe(select(({ theme }) => theme));
export const stepMap$ = store.pipe(select(({ stepMap }) => stepMap));
export const currentStepPosition$ = store.pipe(select(({ currentStepPosition }) => currentStepPosition));
export const submitModel$ = store.pipe(select(({ submitModel }) => submitModel));
export const submitted$ = store.pipe(select(({ submitted }) => submitted));
export const submitMessage$ = store.pipe(select(({ submitMessage }) => submitMessage));

export type AppStore = typeof store;
export const APP_CONFIGURATION_STORE = new InjectionToken<
  ReturnType<typeof createStore>
>('Injection Token For App Configuration Store', {
  providedIn: 'root',
  factory: (): AppStore => store,
});
