import { Injectable, Inject } from '@angular/core';
import { APP_CONFIGURATION_STORE, AppStore } from './app.store';
import { AppConfiguration } from '@root/shared/models/app-configuration.model';
import { CustomPropertiesItem } from '@root/shared/models/custom-properties-item.model';
import { StepMap } from '@root/shared/models/start.model';
import { OnLanding } from '@root/shared/models/on-landing.model';
import { LayoutModel } from '@root/shared/models/layout.model';
import { SubmitStep } from '@root/shared/models/submit.model';

@Injectable({
  providedIn: 'root',
})
export class AppRepository {

  constructor(@Inject(APP_CONFIGURATION_STORE) private _appStore: AppStore) { }

  reset() {
    this.updateAppConfiguration(undefined);
    this.updateOnLanding(undefined);
    this.clearTheme();
    this.clearProcessedSteps();
    this.updateShowFooter({ value: true });
  }


  updateAppConfiguration(appConfiguration: AppConfiguration): void {
    this._appStore.update((state) => ({
      ...state,
      appConfiguration,
    }));
  }


  getAppConfiguration() {
    return this._appStore.getValue().appConfiguration;
  }

  getStepMap() {
    return this._appStore.getValue().stepMap;
  }

  getCurrentStepPosition() {
    return this._appStore.getValue().currentStepPosition;
  }

  getSubmitModel() {
    return this._appStore.getValue().submitModel;
  }

  getLayoutModel() {
    return this._appStore.getValue().layoutModel;
  }


  updateOnLanding(onLanding: OnLanding) {
    this._appStore.update((state) => ({
      ...state,
      onLanding,
    }));
  }

  getOnLanding() {
    return this._appStore.getValue().onLanding;
  }

  updateStepValidation(stepValidation: boolean) {
    this._appStore.update((state) => ({
      ...state,
      stepValidation,
    }));
  }

  updateStepMap(stepMap: StepMap[]) {
    this._appStore.update((state) => ({
      ...state,
      stepMap,
    }));
  }

  updateSubmitModel(submitModel: SubmitStep[]) {
    this._appStore.update((state) => ({
      ...state,
      submitModel,
    }));
  }

  addStepToSubmitModel(step: SubmitStep) {
    const submitModel = this._appStore.value.submitModel;
    // Check if the step with the same StepId already exists
    const stepExists = submitModel.some((existingStep) => existingStep.StepId === step.StepId);

    if (!stepExists) {
      submitModel.push(step);
      this._appStore.update((state) => ({
        ...state,
        submitModel: submitModel,
      }));
    } else {
      console.warn(`Step with StepId ${step.StepId} already exists.`);
    }
  }

  updateCurrentStepPosition(currentStepPosition: number) {
    this._appStore.update((state) => ({
      ...state,
      currentStepPosition,
    }));
  }

  incrementCurrentStepPosition() {
    this._appStore.update((state) => ({
      ...state,
      currentStepPosition: state.currentStepPosition + 1,
    }));
  }

  decrementCurrentStepPosition() {
    this._appStore.update((state) => ({
      ...state,
      currentStepPosition: state.currentStepPosition - 1,
    }));
  }

  updateSubmitted(submitted: boolean) {
    this._appStore.update((state) => ({
      ...state,
      submitted,
    }));
  }

  updateSubmitMessage(submitMessage: string) {
    this._appStore.update((state) => ({
      ...state,
      submitMessage,
    }));
  }

  clearProcessedSteps() {
    this._appStore.update((state) => ({
      ...state,
      processedSteps: [],
    }));
  }

  getProcessedSteps(): StepMap[] {
    return this._appStore.value.processedSteps;
  }

  updateProcessedSteps(processedSteps: StepMap) {
    const processedStepsArray = this._appStore.value.processedSteps;
    processedStepsArray.push(processedSteps);

    this._appStore.update((state) => ({
      ...state,
      processedSteps: undefined,
    }));

    this._appStore.update((state) => ({
      ...state,
      processedSteps: processedStepsArray,
    }));
  }

  getStepValidation(): boolean {
    return this._appStore.getValue().stepValidation;
  }

  updateSkeleton(showSkeleton: boolean) {
    this._appStore.update((state) => ({
      ...state,
      showSkeleton,
    }));
  }

  getSkeleton(): boolean {
    return this._appStore.getValue().showSkeleton;
  }

  updateShowFooter(showFooter: any) {
    this._appStore.update((state) => ({
      ...state,
      showFooter,
    }));
  }

  updateTheme(theme: any) {
    this._appStore.update((state) => ({
      ...state,
      theme,
    }));
  }

  clearTheme() {
    this._appStore.update((state) => ({
      ...state,
      theme: undefined,
    }));
  }

  removeStepFromSubmitModel(stepId: number) {
    const submitModel = this._appStore.value.submitModel;
    const newSubmitModel = submitModel.filter((step) => step.StepId !== stepId);
    this._appStore.update((state) => ({
      ...state,
      submitModel: newSubmitModel,
    }));
  }

  removeSplitStepsFromStepMap(parentStepId: number) {
    const stepMap = this._appStore.value.stepMap;
    const newStepMap = stepMap.filter((step) => step.parentStepId !== parentStepId);
    this._appStore.update((state) => ({
      ...state,
      stepMap: newStepMap,
    }));
  }

  getTheme(): any {
    return this._appStore.value.theme
  }

  updateCustomProperties(customProperties: CustomPropertiesItem[]) {
    this._appStore.update((state) => ({
      ...state,
      customProperties,
    }));
  }

  updateIsSpinning(isSpinning: boolean, spinningCardId: string) {
    this._appStore.update((state) => ({
      ...state,
      isSpinning,
      spinningCardId
    }));
  }

  updateLayoutModel(data: LayoutModel) {
    const layoutModel: LayoutModel = {
      header: data?.header,
      skipAbility: data?.skipAbility,
      skipInformationNote: data?.skipInformationNote,
      skipText: data?.skipText,
      subHeader: data?.subHeader,
      nextButtonTitle: data?.nextButtonTitle
    };
    this._appStore.update((state) => ({
      ...state,
      layoutModel,
    }));
  }
}
