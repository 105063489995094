import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { LanguagesService } from './shared/services/languages.service';
import { BaseComponent } from './shared/components/base-component/base-component';
import { appConfiguration$ } from './store/app.store';
import { AppConfiguration } from './shared/models/app-configuration.model';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { UIService } from './shared/services/ui-service/ui-service.service';
import { Observable, filter } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { AppService } from './shared/services/app.service';
import { appConfiguration } from './shared/settings/common.settings';
import { AppRepository } from './store/app.repository';
import { LandingService } from './pages/landing/services/landing.service';
import * as Sentry from "@sentry/angular";
import { SnackBarNotificationService } from './shared/notifications/services/snackbar-notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, AsyncPipe],
})
export class AppComponent extends BaseComponent implements OnInit {
  private history: string[] = [];
  private lastNavigationIndex: number = -1;
  showRotatePhoneImage = false;
  isDesktop$: Observable<boolean>;

  constructor(private languagesService: LanguagesService,
    private cdr: ChangeDetectorRef,
    private uIService: UIService,
    private landingService: LandingService,
    private zone: NgZone,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private appRepository: AppRepository,
    private router: Router,
    // private firebaseNotificationService: FirebaseNotificationService
    private snackBarNotificationService: SnackBarNotificationService
  ) {
    super();
    this.setupOrientationChange();
  }

  private setupOrientationChange() {
    this.zone.runOutsideAngular(() => {
      window.addEventListener('orientationchange', () => {
        this.showRotatePhoneImage = [90, -90].includes(window.orientation);
        this.cdr.detectChanges();
      });
    });
  }

  ngOnInit(): void {
    window.addEventListener("offline",
      () => this.snackBarNotificationService.warning("No internet connection", 'top')
    );
    this.isDesktop$ = this.uIService.isDesktop$;
    this.subscriptions.add(appConfiguration$.subscribe((appConfiguration: AppConfiguration) => {
      if (!this.isEmpty(appConfiguration)) {
        this.languagesService.getLanguages(appConfiguration.flowIdentifier);
        this.landingService.getTenantTheme(appConfiguration.tenantId);
        //add sentry identity in our case the instance id is the unique identifier
        Sentry.setUser({ id: appConfiguration.instanceId });
      }
    }));
    // this.firebaseNotificationService.requestNotificationPermission();

    this.subscriptions.add(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        const stepId = route.snapshot.paramMap.get(appConfiguration.stepId);
        if (stepId && !route.snapshot.queryParams.scanned) {
          if (Number(stepId) !== this.appRepository.getAppConfiguration()?.stepId)
            this.appRepository.updateLayoutModel({
              ... this.appRepository.getLayoutModel(),
              header: null
            });
          this.appService.updateStepIdInAppConfiguration(Number(stepId));
          this.appService.getCurrentStepPositionByStepId(Number(stepId));
        }
      })
    );
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const currentUrl = event.url;
        // Update history stack
        const currentIndex = this.history.indexOf(currentUrl);
        if (currentIndex !== -1) {
          // URL exists in history: back or forward
          if (currentIndex < this.lastNavigationIndex) {
            const stepId = this.activatedRoute.snapshot.paramMap.get(appConfiguration.stepId);
            this.appService.removeStepFromSubmitModel(Number(stepId));
          }
        } else {
          // New URL: push to stack
          this.history.push(currentUrl);
        }

        this.lastNavigationIndex = this.history.indexOf(currentUrl);
      }
    });
  }
}