import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRepository } from '../../store/app.repository';
import { ApplicationRoutes } from '../settings/common.settings';
import { AppConfiguration } from '../models/app-configuration.model';
import {
  HttpHeaders,
  HttpErrorResponse,
  HttpStatusCode,
  HttpClient,
  HttpResponse,
} from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StartModel, StepMap } from '../models/start.model';
import { OutputKeysRepository } from '../store/output-keys/output-keys.repository';
import {
  OutputPropertyItemModel,
  OutputPropertyKeyValueModel,
} from '../models/output-keys.model';
import { v4 as uuidv4 } from 'uuid';
import { DocumentCaptureRepository } from '@root/pages/blocks/document-capture/store/document-capture.repository';
import { LayoutModel } from '../models/layout.model';
import { DataEncryptionService } from './encryption/dataencryption.service';
import { SubmitStep } from '../models/submit.model';
import { ComparisonOperator, Condition, ConditionOperator } from '../models/conditions.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private http: HttpClient,
    private appRepository: AppRepository,
    private outputKeysRepository: OutputKeysRepository,
    private documentCaptureRepository: DocumentCaptureRepository,
    private dataEncryptionService: DataEncryptionService,
    private translateService: TranslateService,
    private route: Router
  ) { }

  updateStepIdAndStepDefinitionInAppConfiguration(
    stepId: number,
    stepDefinition: string
  ) {
    const appConfiguration = this.appRepository.getAppConfiguration();
    if (appConfiguration) {
      this.appRepository.updateAppConfiguration({
        tenantId: appConfiguration.tenantId,
        blockId: appConfiguration.blockId,
        stepId: stepId,
        stepDefinition: stepDefinition,
        instanceId: appConfiguration.instanceId,
        applicationId: appConfiguration.applicationId,
        flowName: appConfiguration.flowName,
        instanceHash: appConfiguration.instanceHash,
        flowIdentifier: appConfiguration.flowIdentifier,
        flowInstanceId: appConfiguration.flowInstanceId,
        defaultLanguageId: appConfiguration.defaultLanguageId,
        chatEnabled: appConfiguration.chatEnabled,
      });
    } else {
      this.route.navigate([ApplicationRoutes.AreYouLost]);
    }
  }

  updateStepIdInAppConfiguration(stepId: number) {
    const appConfiguration = this.appRepository.getAppConfiguration();
    if (appConfiguration) {
      this.appRepository.updateAppConfiguration({
        tenantId: appConfiguration.tenantId,
        blockId: appConfiguration.blockId,
        stepId: stepId,
        stepDefinition: appConfiguration.stepDefinition,
        instanceId: appConfiguration.instanceId,
        applicationId: appConfiguration.applicationId,
        flowName: appConfiguration.flowName,
        flowIdentifier: appConfiguration.flowIdentifier,
        instanceHash: appConfiguration.instanceHash,
        flowInstanceId: appConfiguration.flowInstanceId,
        defaultLanguageId: appConfiguration.defaultLanguageId,
        chatEnabled: appConfiguration.chatEnabled,
      });
    } else {
      this.route.navigate([ApplicationRoutes.AreYouLost]);
    }
  }

  callStartApi(
    instanceHash: string,
    flowInstanceId?: string,
    stepId?: number,
    instanceId?: string,
    callBackFun?: () => void
  ) {
    let httpHeaders;
    if (flowInstanceId) {
      httpHeaders = {
        headers: new HttpHeaders({
          'X-Flow-Instance-Id': flowInstanceId,
          InterceptorSkipConfigurationHeader: '',
        }),
      };
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          InterceptorSkipConfigurationHeader: '',
        }),
      };
    }
    this.http
      .get<StartModel>(
        `${environment.assentifyEndPoint}Manager/Start/${instanceHash}`,
        httpHeaders
      )
      .pipe(
        map((response) => {
          if (callBackFun) {
            callBackFun();
          }
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 0) {
            // A client-side or network error occurred
          } else {
            // The backend returned an unsuccessful response code.
            if (error.status === HttpStatusCode.NotFound) {
              this.route.navigate([ApplicationRoutes.AreYouLost]);
            }
            if (error.status === HttpStatusCode.InternalServerError) {
              this.route.navigate([ApplicationRoutes.AreYouLost]);
            }
          }
          // Return an observable with a user-facing error message.
          // new Error('Something bad happened; please try again later.')
          return throwError(() => {
            // empty
          });
        })
      )
      .subscribe((data: StartModel) => {
        const appConfiguration: AppConfiguration = {
          blockId: data.blockIdentifier,
          flowInstanceId: data.flowInstanceId,
          instanceId: instanceId ? instanceId : data.instanceId,
          stepId: stepId ? stepId : data.stepId,
          instanceHash: instanceHash,
          applicationId: data.applicationId,
          flowName: data.flowName,
          tenantId: data.tenantIdentifier,
          flowIdentifier: data.flowIdentifier,
          stepDefinition: 'step',
          defaultLanguageId: data.defaultLanguageId,
          chatEnabled: data.chatEnabled,
        };

        this.appRepository.updateStepMap(data.stepMap);
        this.appRepository.updateCurrentStepPosition(0);
        this.appRepository.updateSubmitModel([]);

        if (data.customProperties)
          this.appRepository.updateCustomProperties(data.customProperties);
        else this.appRepository.updateCustomProperties([]);
        this.appRepository.updateAppConfiguration(appConfiguration);
      });
  }

  callSubmitApi() {
    this.appRepository.updateSubmitted(false);
    this.appRepository.updateSubmitMessage(this.translateService.instant('wrap-up.submitting-message'));
    const url = `${environment.assentifyEndPoint}Manager/Submit`;
    const data = this.appRepository.getSubmitModel();
    this.http.post(url, data, { observe: 'response' }).subscribe({
      next: (response: HttpResponse<any>) => {
        if (response.status >= 200 && response.status < 300) {
          this.appRepository.updateSubmitted(true);
          this.appRepository.updateSubmitModel([]);
        }
      }, error: () => {
        this.appRepository.updateSubmitMessage('An unexpected error occurred. Please try again later.');
      }
    });
  }

  getNextStep() {
    const appConfiguration = this.appRepository.getAppConfiguration();
    if (appConfiguration) {
      if (
        appConfiguration.stepDefinition === ApplicationRoutes.DocumentCapture
      ) {
        this.uploadBulkDocuments(appConfiguration);
      } else {
        const outputKeys = this.getOutputKeys(appConfiguration);
        if (outputKeys) {
          this.appRepository.updateLayoutModel({} as LayoutModel);
          this.callNextApi(outputKeys, appConfiguration);
        }
      }
    }
  }

  getStepCachedContent(stepId: number, collectedInfo: { [key: string]: string }) {
    this.http
      .post<{ [key: string]: string }>(
        `${environment.assentifyEndPoint}Manager/GetStepCachedContent/${stepId}`,
        collectedInfo
      )
      .subscribe((data) => {
        if (data) {
          Object.entries(data).forEach(([key, value]) => {
            this.outputKeysRepository.addOutputProperty(key, value);
          });
        }
        this.updateStepIdInAppConfiguration(stepId);
        this.getNextStep();
      });
  }

  callNextApi(
    outputKeys: OutputPropertyItemModel,
    appConfiguration: AppConfiguration
  ) {
    this.dataEncryptionService.getKey().subscribe((value) => {
      const ExtractedInformation: { [key: string]: string } = {};
      for (const key in outputKeys) {
        // eslint-disable-next-line no-prototype-builtins
        if (outputKeys.hasOwnProperty(key) && outputKeys[key].value) {
          const encryptedContent = this.dataEncryptionService.encryptData(
            outputKeys[key].value.toString(),
            value
          );
          console.log(encryptedContent);
          ExtractedInformation[key] = outputKeys[key].value.toString();
        }
      }
      const stepMap = this.appRepository.getStepMap();
      this.appRepository.incrementCurrentStepPosition();


      const result = stepMap[this.appRepository.getCurrentStepPosition()];

      const step: SubmitStep = {
        StepId: appConfiguration.stepId,
        StepDefinition: appConfiguration.stepDefinition,
        ExtractedInformation: ExtractedInformation,
      }
      this.appRepository.addStepToSubmitModel(step);
      if (appConfiguration.stepDefinition === ApplicationRoutes.WrapUp) {
        this.callSubmitApi();
      }
      if (result) {
        if (result.stepDefinition === ApplicationRoutes.DataRelay) {
          this.getStepCachedContent(result.id, ExtractedInformation);
        } else if (result.stepDefinition === ApplicationRoutes.Split) {
          this.appRepository.removeSplitStepsFromStepMap(Number(result.id));
          const newStepMap = this.appRepository.getStepMap();
          const branchIndex = this.evaluateConditions(result);
          const steps = this.insertSteps(newStepMap, this.appRepository.getCurrentStepPosition(), result.branches[branchIndex]);
          this.appRepository.updateStepMap(steps);
          this.getNextStep();
        } else {
          this.appRepository.updateStepValidation(false);
          const { id, stepDefinition } = result;
          this.appRepository.updateProcessedSteps({
            id: id,
            stepDefinition,
          });
          this.route.navigate([`block/${stepDefinition}/${id}`]);
        }
      } else {
        window.sessionStorage.clear();
      }
    });
  }

  getOutputKeys(
    appConfiguration: AppConfiguration
  ): OutputPropertyItemModel | undefined {
    return this.outputKeysRepository.getOutputKeysByStepId(
      appConfiguration.stepId
    );
  }

  uploadBulkDocuments(appConfiguration: AppConfiguration) {
    const documentCaptureKeyValues =
      this.documentCaptureRepository.getDocumentCaptureKeyValues()[
      appConfiguration.stepId
      ];
    if (
      documentCaptureKeyValues &&
      Object.keys(documentCaptureKeyValues).length > 0
    ) {
      const url = `${environment.blobStorage
        }/v2/Document/UploadBulk/documentcapture/${uuidv4()}`;
      const formData: FormData = new FormData();
      for (const key in documentCaptureKeyValues) {
        if (
          // eslint-disable-next-line no-prototype-builtins
          documentCaptureKeyValues.hasOwnProperty(key) &&
          documentCaptureKeyValues[key] instanceof Blob
        ) {
          const file = documentCaptureKeyValues[key] as Blob;
          const fileType = file.type.split('/')[1];
          formData.append('files', file, `${key}.${fileType}`);
        }
      }

      formData.append('additionalValues', appConfiguration.tenantId);
      formData.append('additionalValues', appConfiguration.blockId);
      formData.append('additionalValues', appConfiguration.flowIdentifier);
      formData.append('additionalValues', appConfiguration.stepId.toString());
      formData.append('additionalValues', appConfiguration.instanceId);

      this.http
        .post(url, formData, {
          headers: new HttpHeaders({
            InterceptorShowSpinner: '',
          }),
        })
        .subscribe((data: OutputPropertyKeyValueModel) => {
          const outputProperties: OutputPropertyItemModel = {};

          for (const key in data) {
            this.outputKeysRepository.addOutputProperty(key, data[key]);
          }
          const outputKeys = this.getOutputKeys(appConfiguration);
          for (const [key, value] of Object.entries(outputKeys)) {
            if (Object.keys(data).some(dataKey => key.startsWith(dataKey))) {
              outputProperties[key] = value;
            }
          }
          this.callNextApi(outputProperties, appConfiguration);
        });
      return;
    }

    this.callNextApi({}, appConfiguration);
  }

  getCurrentStepPositionByStepId(stepId: number) {
    const position = this.appRepository.getStepMap().findIndex(step => step.id === stepId);
    this.appRepository.updateCurrentStepPosition(position);
  }

  removeStepFromSubmitModel(stepId: number) {
    this.appRepository.removeStepFromSubmitModel(stepId);
  }

  evaluateConditions(stepData: any): number {
    if (!stepData.stepMapBranches?.some((branch: any) => branch.conditions?.length > 0)) {
      return 0;
    }

    for (const branch of stepData.stepMapBranches) {
      if (branch.conditions?.length) {
        const branchResult = this.evaluateConditionGroup(branch.conditions);
        if (branchResult) {
          return branch.branchIndex;
        }
      }
    }

    return 0;
  }

  private evaluateConditionGroup(conditions: Condition[]): boolean {
    const extractedInformation = this.outputKeysRepository.getOutputKeys();
    const inputValue = this.findValueByKey(extractedInformation, conditions[0].key);
    let result = this.evaluateSingleCondition(conditions[0], inputValue);

    for (let i = 1; i < conditions.length; i++) {
      const currentCondition = conditions[i];
      const inputValue = this.findValueByKey(extractedInformation, currentCondition.key);
      const currentResult = this.evaluateSingleCondition(currentCondition, inputValue);
      if (currentCondition.conditionOperator === ConditionOperator.And) {
        result = result && currentResult;
      } else {
        result = result || currentResult;
      }
    }

    return result;
  }

  private evaluateSingleCondition(condition: Condition, compareValue: any): boolean {

    switch (condition.operator) {
      case ComparisonOperator.Contains:
        return compareValue?.includes(condition.value);
      case ComparisonOperator.EndsWith:
        return compareValue?.endsWith(condition.value);
      case ComparisonOperator.Equal:
        return compareValue === condition.value;
      case ComparisonOperator.GreaterThan:
        return compareValue > condition.value;
      case ComparisonOperator.GreaterThanOrEqual:
        return compareValue >= condition.value;
      case ComparisonOperator.LessThan:
        return compareValue < condition.value;
      case ComparisonOperator.LessThanOrEqual:
        return compareValue <= condition.value;
      case ComparisonOperator.NotEqual:
        return compareValue !== condition.value;
      case ComparisonOperator.StartsWith:
        return compareValue?.startsWith(condition.value);
      default:
        return false;
    }
  }


  findValueByKey(
    data: OutputPropertyItemModel,
    searchKey: string
  ): string | boolean | null {
    if (!data || !searchKey) return null;

    const foundEntry = Object.entries(data).find(([key]) =>
      key.toLowerCase().includes(searchKey.toLowerCase())
    );
    return foundEntry ? foundEntry[1].value : null;
  }

  insertSteps(stepMap: StepMap[], index: number, stepsToInsert: any) {
    // Insert the steps after the specified index
    const updatedStepMap = [
      ...stepMap.slice(0, index + 1),
      ...stepsToInsert,
      ...stepMap.slice(index + 1)
    ];

    return updatedStepMap;
  }
}
