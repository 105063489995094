import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BaseComponent } from '@root/shared/components/base-component/base-component';
import { Lang } from '@root/shared/models/enums/languages.enum';
import { LayoutModel } from '@root/shared/models/layout.model';
import { ChangeLanguageService } from '@root/shared/services/change-language.service';
import { appConfiguration$, layoutModel$, theme$ } from '@root/store/app.store';
import { selectedLanguage$ } from '@root/shared/store/shared/shared.store';
import { Observable, combineLatest, take } from 'rxjs';
import { StepperComponent } from '../stepper/components/stepper/stepper.component';
import { MatIconModule } from '@angular/material/icon';
import { Theme } from '@root/shared/models/theme.model';
import { AsyncPipe } from '@angular/common';
import { StepperType } from '@root/shared/models/enums/stepper-type.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [StepperComponent, MatIconModule, AsyncPipe]
})
export class HeaderComponent extends BaseComponent implements OnInit {
  layoutModel$: Observable<LayoutModel> = layoutModel$;
  theme$: Observable<Theme> = theme$;
  stepperType = StepperType;

  constructor(private changeLanguageService: ChangeLanguageService) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([appConfiguration$, selectedLanguage$])
        .pipe(take(1))
        .subscribe(([appConfiguration, selectedLanguage]) => {
          if (selectedLanguage)
            this.changeLanguageService.changeLang(selectedLanguage.languageId === 1 ? Lang.English : Lang.Arabic);
          else
            this.changeLanguageService.changeLang(appConfiguration.defaultLanguageId === 1 ? Lang.English : Lang.Arabic);
        })
    );

  }


}
